import axios from "./Axios.js";
const URL = process.env.VUE_APP_SERVER_URI+"comments";
class User {	
	static axios(){
		return axios.axios();
	}
	static async save(data) {
		let url=URL;
		data.securityToken =getCookie('session');
		const res = await axios.post(url,data);
		return res.data;
    }
    static get(headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/find/list"
			try {
				const res = await axios.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await axios.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async update ( id,data ) {
		var url = (URL+"/"+id)
		data.securityToken =getCookie('session');
		var { data } = await axios.put(url,data);
		return data
    }
}
function getCookie(key){
	let cookieList=document.cookie.split(';');
	let obj={};
	for (let index = 0; index < cookieList.length; index++) {
		const element = cookieList[index];
		let item=element.split('=');
		obj[item[0]]=item[1];
	}
	return obj[key];
}
export default User;