<template>
    <v-container grid-list-xs>
        
        <v-row
            align="center"
            justify="center"
        >
            <v-col cols="12" sm="11" md="10" lg="8">
                <h2 class="subheading greytext--text">
                    <span style="border-bottom:solid 3px #8000FF">Nues</span>tros <span style="border-bottom:solid 3px #8000FF">S</span>ervi<span style="border-bottom:solid 3px #8000FF">cios</span>
                </h2>
            </v-col>
            <v-col cols="12" sm="11" md="10" lg="8">
                <v-row >
                    <v-col cols="12" sm="6" lg="3" v-for="(item,index) in serviceList" :key="index+'servicios'">
                        <v-hover v-slot="{ hover }" >
                            <v-card color="#181818" class="hover-card" height="100%" dark :class="{ 'on-hover': hover }" :elevation="hover ? 12 : 2" >
                                <v-card-title primary-title>
                                    {{item.title}}
                                </v-card-title>
                                <v-card-text>                                
                                    
                                        <v-img :src="item.img" height="200px" alt="">
                                            <div class="purple-transparent-background"></div>
                                        </v-img>
                                        <div class="font-weight-thin text-caption ma-4"  style="line-height : 25px; ">
                                            {{item.description}}
                                        </div>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <v-card color="#1D1E20" class="my-6" dark ref="counter">
                    <v-card-text>
                        <div style="background:#191A1C" >
                            <v-row v-scroll:#scroll-target="onScroll">
                                <v-col cols="12" md="4" >
                                    <div class="text-center my-6">
                                        <div class="greytext--text text-h4" >
                                            {{totalCustomers}}<span class="primary--text">+</span>
                                        </div>
                                        <div class=" text-overline" >
                                            <span class="">Clientes Registrados</span>
                                        </div>
                                    </div>
                                            
                                </v-col>
                                <v-col cols="12" md="4" >
                                    <div class="text-center  my-6">
                                        <div class="greytext--text text-h4" >
                                            {{totalServices}}<span class="primary--text">+</span>
                                        </div>
                                        <div class=" text-overline" >
                                            <span class="">Servicios realizados</span>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4" >
                                    <div class="text-center  my-6">
                                        <div class="greytext--text text-h4" >           
                                            7<span class="primary--text">+</span>
                                        </div>
                                        <div class=" text-overline" >
                                            <span class="">Años de servicio</span>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapState } from 'vuex'
import RequestedServices from '@/services/RequestedServices'
import Customer from '@/services/Users'
export default {
  name: 'App',

  components: {
  },
  watch:{
  },
  data: () => ({
    totalServices:0,
    totalCustomers:0,
    successScroll:false,
    carouselItems:[
        require('@/assets/images/K7mpa6NVWDk.jpg'),
        require('@/assets/images/qnmDeodw37E.jpg'),
        require('@/assets/images/FkAZqQJTbXM.jpg'),
        require('@/assets/images/GAKR9WaY.jpg'),
        require('@/assets/images/a6iReFVwcOM.jpg')
    ],
    serviceList:[
        {
            title:"Tintes",
            description:"Tu cabello expresa mas que actitud e individualidad deja que nuestro equipo de expertos te ayude.El tinte dará color, elegancia e intensidad a tu cabello. ",
            img:require("@/assets/images/IMG_20220526_011641.jpg")
        },
        {
            title:"Uñas",
            description:"Deja que tus manos hablen por ti.Esmaltes y acrílicos de la mas alta calidad a precios increíbles.",
            img:require("@/assets/images/244672690.jpg")
        },
        {
            title:"Cortes de cabello",
            description:"Un buen corte es la base esencial de un buen peinado y de tu personalidad. Servicio profesional de corte de cabello.",
            img:require("@/assets/images/GAKR9WaY.jpg")
        },
        {
            title:"Peinados",
            description:"Luce impecable de pies a cabeza, realizamos el peinado adecuado para ti, desde un alaciado o peinado casual, hasta ese peinado para xv años o bodas.",
            img:require("@/assets/images/FkAZqQJTbXM.jpg")
        }
    ]
    
  }),
  methods:{
    async contServices(){
        try {            
            let count=await RequestedServices.count();
            let interval=setInterval(() => {
                if(this.totalServices<count){
                    this.totalServices++;
                }else{
                    clearInterval(interval);
                }
            }, 20);
        } catch (error) {
            console.log(error);
        }
    },
    async contCustomers(){
        try {            
            let count=await Customer.count();
            let interval=setInterval(() => {
                if(this.totalCustomers<count){
                    this.totalCustomers++;
                }else{
                    clearInterval(interval);
                }
            }, 10);
        } catch (error) {
            console.log(error);
        }
    }
  },
  mounted(){
    let self=this;
    window.addEventListener('scroll', function(e) {
      let counter=self.$refs.counter;
        if(counter.$el.getBoundingClientRect().y-self.appHeight<=0 && !self.successScroll){
            self.successScroll=true
            self.contServices();
            self.contCustomers();            
        }  
    });
  },
  computed:{
    ...mapState(['appHeight'])
  }
};
</script>
<style scoped>
.v-card.hover-card {
  transition: opacity .4s ease-in-out;
}

.v-card.hover-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>