
<template>
  <div class="my-5" style="height: 50px;">
    <div class="typed" style="position:absolute">
      {{ text}}<span style="font-size: 12px; color: transparent">{{ prompt }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "WriteText",
  props: {
    wordList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    text: "",
    prompt: "|",
    static: true,
  }),
  methods: {
    changeLetters() {
      let arrayWord = this.wordList[0].split("");
      let i = 0;
      let j = 0;
      let rw = false;
      let start = 0;
      let print = setInterval(() => {
        if (start < 8) {
          this.static = false;
          start++;
        } else {
          this.static = true;
          if (!rw) {
            if (i === arrayWord.length + 4) {
              rw = true;
              this.static = false;
            } else if (i < arrayWord.length) {
              this.text += arrayWord[i];
              this.static = true;
            } else {
              this.static = false;
            }
            i++;
          } else if (rw) {
            i--;
            this.text = this.text.substring(0, i);
            if (i === 0) {
              j++;
              if (j === this.wordList.length) j = 0;
              arrayWord = this.wordList[j].split("");
              rw = false;
              start = 0;
              this.static = true;
            }
          }
        }
      }, 200);
    },
    promptChange() {
      let change = true;
      setInterval(() => {
        if (!this.static) {
          if (change) this.prompt = "|";
          else this.prompt = " ";
          change = !change;
        } else {
          this.prompt = "|";
        }
      }, 300);
    },
  },
  mounted() {
    this.changeLetters();
    this.promptChange();
  },
};
</script>
<style scoped>
.typed {
  color: transparent;
  height: 50px;
  font-family: "Saira Extra Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 60px;
  -webkit-text-stroke: 1px #ffffff;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}
@media (max-width: 959px) {
  .typed {
    font-size: 30px;
  }
}
</style>