<template>
    <v-container grid-list-xs>        
        <v-row
            align="center"
            justify="center"
        >
            <v-col cols="11" sm="11" md="10" lg="8">
                <h2 class="subheading greytext--text">
                    <span style="border-bottom:solid 3px #8000FF">Con</span>tact<span style="border-bottom:solid 3px #8000FF">a</span>n<span style="border-bottom:solid 3px #8000FF">os</span>
                </h2>
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="8">
                <v-row>
                    <v-col cols="12" md="4" @click="openLocation" style="cursor:pointer">
                        <v-row>
                            <v-col cols="3">
                                <v-avatar
                                    size="50"
                                    color="primary"
                                >
                                    <v-icon color="white">fas fa-map-marker</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="9">
                                Cda. Sauces S/N local 4. Colonia tlalpexco. CP.  07188
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row>
                            <v-col cols="3">
                                <v-avatar
                                    size="50"
                                    color="primary"
                                >
                                    <v-icon color="white">fas fa-phone</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="9">
                                <a href="tel:5513341838">
                                    <v-row>
                                        <v-col cols="2" class="ma-0 pa-0">
                                            <v-icon small color="primary">phone</v-icon>
                                        </v-col>
                                        <v-col class="text-caption ma-0 pa-0">
                                            5513341838
                                        </v-col>
                                    </v-row>
                                </a>
                                <a href="https://wa.link/03hp21">
                                    <v-row>
                                        <v-col cols="2" class="ma-0 pa-0">
                                            <v-icon small color="primary">fab fa-whatsapp</v-icon>
                                        </v-col>
                                        <v-col class="text-caption ma-0 pa-0">
                                            5514965093
                                        </v-col>
                                    </v-row>
                                </a>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-row>
                            <v-col cols="3">
                                <v-avatar
                                    size="50"
                                    color="primary"
                                >
                                    <v-icon color="white">fas fa-envelope</v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="9">
                                contacto@labrujita.mx
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                
            </v-col>
            <v-col cols="11" sm="11" md="10" lg="8">
                <v-divider color="#8000FF"></v-divider>
                <v-row>
                    <v-col cols="12" sm="12" md="5" lg="4">
                        
                        <v-form @submit.prevent="saveComments()" ref="commentform" lazy-validation>
                            <v-row>
                                <v-col cols="12" sm="6" md="12">
                                    Nombre *
                                    <v-text-field
                                        name="name"
                                        dense
                                        v-model="form.name"
                                        outlined
                                        dark
                                        color="primary"
                                        :rules="rules.fieldRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                     Teléfono
                                    <v-text-field
                                        name="phone"
                                        dense
                                        v-model="form.phoneNumber"
                                        outlined
                                        dark
                                        color="primary"
                                        :rules="phoneRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" >
                                    Correo electrónico
                                    <v-text-field
                                        name="email"
                                        dense
                                        v-model="form.email"
                                        outlined
                                        dark
                                        :rules="emailRules"
                                        color="primary"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" >
                                    Comentarios
                                    <v-textarea
                                        name="comment"
                                        dense
                                        v-model="form.comment"
                                        outlined
                                        dark
                                        color="primary"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="white" block outlined type="submit">Enviar mensaje</v-btn>                                    
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                    <v-col cols="12" sm="12" md="7" lg="8">
                        <div style="width:100%;height:100%;min-height:300px;">
                            <GmapMap
                                :center="center"
                                :zoom="18"
                                style="width: 100%; height: 100%"
                                :options="mapStyle"
                                @click="openLocation()"
                            >
                                <GmapMarker
                                    :position="center"
                                    :draggable="false"
                                    @click="openLocation()"
                                />
                            </GmapMap>
                        </div>
                    </v-col>
                </v-row>            
            </v-col>    
        </v-row>
        <v-dialog
        v-model="errorDialog"
        transition="dialog-bottom-transition"
        max-width="500"
        persistent
        >
            <v-card rounded="xs" color="#393B3D" dark >
                <v-card-title primary-title class="error">
                    <span >
                        Algo salio mal
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="updateThis">
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-body-1 text-center">
                    La pagina se actualizara. <br>
                    Vuelve a intentarlo nuevamente.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="updateThis">
                        aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="sendDialog"
        transition="dialog-bottom-transition"
        max-width="500"
        persistent
        >
            <v-card rounded="xs" color="#393B3D" dark >
                <v-card-title primary-title class="success">
                    <span >
                        Completado
                    </span>
                    
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="text-body-1 text-center mt-5">
                    Gracias por tus comentarios, son muy importantes para nosotros. <br>
                     <v-checkbox label="¿Me gustaria recibir una respuesta?" v-model="reply"></v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="sendSuccess">
                        aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>    
</template>
<script>
import {gmapApi} from 'vue2-google-maps'

import Leads from '../services/Leads';
import Comments from '../services/Comments';
import WebToken from '../services/WebToken';
import Loading from '../components/Loading';
import darkStyle from '../components/maps/darkStyle.json'
import rieggoStyle from '../components/maps/rieggoStyle.json'
import { mapMutations,mapState } from 'vuex';
export default {
    components:{Loading,},
    data:()=>({
        errorDialog:false,
        sendDialog:false,
        center:{lat:19.545371227499643,lng:-99.12518727657297},
        typeSelected:{},
        comment:{},
        form:{
            phoneNumber:'',
            email:''
        },
        rules:{
            fieldRules:[
                (v)=>!!v || 'El campo es obligatorio'
            ]
        },
        reply:false
    }),
    methods:{
        ...mapMutations(['setLoading']),
        updateThis(){
            location.reload();
        },
        async sendSuccess(){
            this.setLoading(true);
            try {
                await Comments.update(this.comment._id,{reply:this.reply});
                this.comment={};
                this.sendDialog=false;
            } catch (error) {
                console.log(error);
            }finally{
                setTimeout(() => {                    
                    this.setLoading(false);
                }, 1000);
            }
        },
        async getToken(){
            this.form.securityToken=await WebToken.get();
        },
        async saveComments(){
            this.setLoading(true);
            try {
                if (!this.$refs.commentform.validate())throw {error:'Error de validación'}
                let res=await Comments.save(this.form);
                this.comment=res;
                this.form={phoneNumber:'',email:''}
                this.$refs.commentform.resetValidation();
                this.sendDialog=true;
            } catch (error) {
                this.errorDialog=true;
            }finally{
                setTimeout(() => {                    
                    this.setLoading(false);
                }, 1000);
            }
        },
        openLocation(){
            window.open("https://goo.gl/maps/SjFkXdtZNgRvdpY86",'_blank');
        },
        async save(){
            try {

                let res=await Leads.save(this.form);
                location.href="/success"
            } catch (error) {
                console.log(error);
            }
            
        }
    },
    created(){
        
    },
    async mounted(){
        },
    computed:{
        
        phoneRules(){
            let self=this;
            return[
                (v)=> (v.length>0 || self.form.email.length>0) || 'Ingrese al menos un medio de contacto',
                (v)=>(v.length>0 || (/[\(]?[\+]?(\d{2}|\d{3})[\)]?[\s]?((\d{6}|\d{8})|(\d{3}[\*\.\-\s]){3}|(\d{2}[\*\.\-\s]){4}|(\d{4}[\*\.\-\s]){2})|\d{8}|\d{10}|\d{12}/).test(v)) || 'El número ingresado no es valido'
            ]
        },
        emailRules(){
            let self=this;
            return[
                (v)=>(!!v || v.length>0 || self.form.phoneNumber.length>0) || 'Ingrese al menos un medio de contacto',
                (v)=>(self.form.phoneNumber.length>0 || (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/).test(v))  || 'El correo ingresado no es valido'
            ]
        },
        google:gmapApi,
        mapStyle(){
            let opt={
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true,
                draggable:false,
            }
            // return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign({styles:[]},opt)
            return this.$vuetify.theme.dark?Object.assign(darkStyle,opt):Object.assign(rieggoStyle,opt)
        },
    }

}
</script>