import axios from "axios";
const URL = process.env.VUE_APP_SERVER_URI+"webtoken";

class WebToken {
	//Get Posts
	static async get() {  
		return await axios.get(URL);
    }
    
}

export default WebToken;