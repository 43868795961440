<template>
  <div  >
    <Paralax/>
    <v-divider color="#222222"></v-divider>
    <AboutUs/>
    <v-divider color="#222222"></v-divider>
    <OurServices/>
    <v-divider color="#222222"></v-divider>
    <Contact/>
  </div>
</template>

<script>
import MenuButton from '@/components/MenuButton';
import Witch from '@/views/Witch';
import Paralax from '@/components/Paralax';
import AboutUs from '@/components/AboutUs';
import Contact from '@/components/Contact';
import OurServices from '@/components/OurServices';
import logo from '@/assets/logo.png';
import FirstPage from './FirstPage.vue';
import { mapState } from 'vuex'
export default {
  name: 'App',

  components: {
    MenuButton,
    Witch,
    FirstPage,
    Paralax,
    AboutUs,
    OurServices,
    Contact
  },
  watch:{
  },
  data: () => ({
    menuActive:false,
    menuItems:[
      // {text:"Manicura",icon:"",active:false,list:[
      //   {text:"Diseños",link:"/manicure/designs"},
      //   {text:"Agendar cita",link:"/manicure/appoiment"},
      // ]},
      {text:"¿Quienes somos?",link:"/about",icon:""},
      {text:"Contactanos",link:"/contact",icon:""},
      {text:"Galeria",link:"/galery",icon:""},
    ],
    userMenuItems:[
      {text:"Registrar",type:"link",link:"/user/register",icon:"fa-user-plus",auth:false},
      {text:"Iniciar Sesión",type:"link",link:"/user/login",icon:"fa-sign-in-alt",auth:false},
      {text:"Perfil",type:"link",link:"/user/profile",icon:"fa-user",auth:true},
    ],
    
  }),
  methods:{
    closeSesion(){
      this.$session.clear("witchToken");
      this.$store.commit("userSet",this.$session.get("user"))
      location.href="#/";
    }
  },
  created(){
    this.$store.commit("userSet",this.$session.get("user"))
  },
  computed:{
    ...mapState(['appHeight']),
    logo(){
      
      return logo;
    },
    user(){
      return this.$store.state.user;
    }
  }
};
</script>
