<template>
    <v-container grid-list-xs>
        
        <v-row
            align="center"
            justify="center"
        >
            <v-col cols="12" sm="11" md="10" lg="8">
                <h2 class="subheading greytext--text">
                    <span style="border-bottom:solid 3px #8000FF">Acer</span>ca de no<span style="border-bottom:solid 3px #8000FF">sot</span>ro<span style="border-bottom:solid 3px #8000FF">s</span>
                </h2>
            </v-col>
            <v-col cols="12" sm="11" md="10" lg="8">
                <v-row>
                    <v-col cols="12" md="6" lg="5">
                        <div style="padding:10px;border:1px solid #8000FF;height:30">
                            <v-carousel :show-arrows="false" hide-delimiters cycle height="300">
                                <v-carousel-item
                                v-for="(item,i) in carouselItems"
                                :key="i"
                                :src="item"
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                                
                                >
                                    <div style="background:#100435a0;position:absolute;height:100%;width:100%">

                                    </div>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="7">
                        <h3 class="mb-4">
                            Nuestro salón
                        </h3>
                        <v-divider color="#8000FF"></v-divider>
                        <div class="font-weight-thin text-caption ma-4"  style="line-height : 25px;">
                            Nuestro salón de belleza fue fundado en 2016, y desde entonces estamos tratando de hacer que nuestros clientes estén más felices y seguros.
                            Empezamos como un pequeño grupo de personas inspirados. 
                            ¡Ahora contamos con un equipo completo de especialistas, 
                            que saben lo que hacen y tienen todas las habilidades para 
                            realzar la belleza natural en usted! ¡Trabajamos con equipos de
                            alta calidad y solo utilizamos materiales de la mejor calidad!
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: 'App',

  components: {
  },
  watch:{
  },
  data: () => ({
    carouselItems:[
        require('@/assets/images/K7mpa6NVWDk.jpg'),
        require('@/assets/images/qnmDeodw37E.jpg'),
        require('@/assets/images/FkAZqQJTbXM.jpg'),
        require('@/assets/images/GAKR9WaY.jpg'),
        require('@/assets/images/a6iReFVwcOM.jpg')
    ]
    
  }),
  methods:{
  },
  created(){
  },
  computed:{
    ...mapState(['appHeight'])
  }
};
</script>
