<template>
    <div>
        <v-row>
            <v-col cols="1" style="">
                <v-divider vertical color="white" style="border:solid 1px white"></v-divider>
            </v-col>
            <v-col>
                <div class="text-body-2 grey--text" >
                  {{subtitle}}
                </div>
                <div class="text-caption mt-2">
                  {{text}}
                </div>
                <div v-for="(option,index) in listOptions" :key="index+'options'">
                    <v-row>
                        <v-col cols="4" class="ma-0 pa-0">
                            <v-icon small color="primary">{{option.icon}}</v-icon>
                        </v-col>
                        <v-col class="text-caption ma-0 pa-0">
                            {{option.text}}
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name:"InfoCard",
    props:{
        subtitle:{
            type:String,
            default:""
        },
        text:{
            type:String,
            default:""
        },
        listOptions:{
            type:Array,
            default:()=>([])
        }
    },
}
</script>