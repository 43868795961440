<template>
<div style="cursor: pointer;display:inline-block;height:50px;margin-left:10px;margin-right:10px;margin-top:-20px">
    <div v-if="!!to" @mouseover="active=true" @mouseleave="active=false" class="text-center">
        <router-link :to="to" style="font-family: 'Fira Sans Condensed', sans-serif;"  class="text-center">
            <div :class="`line-bottom ${active?'active':''}`" :style="`margin-bottom:8px;width:100%;height:2px; background:${color}`"></div> 
            <span style="font-family: 'Fira Sans Condensed', sans-serif;color:#8000FF">{{text}}</span>                
        </router-link>
    </div>
    
    <div v-else @mouseover="active=true" @mouseleave="active=false" class="text-center">
        <div :class="`line-bottom ${active?'active':''}`" :style="`margin-bottom:8px;width:100%;height:2px; background:${color}`"></div> 
         {{text}}
    </div>
</div>
    
         
</template>
<script>
export default {
    props:["color","text","to"],
    data:()=>({
        active:false
    }),
    methods:{
        linkOpen(){
            
            if(!!this.to) location.href=this.to
        }
    }
}
</script>
<style >
    .line-bottom{
        -webkit-transition: all .2s ease .05s; 
        -moz-transition: all .2s ease .05s; 
        -o-transition: all .2s ease .05s; 
        -ms-transition: all .2s ease .05s; 
        transition: all .2s ease .05s;
        transform: translate(0, 4px) scale(0, 0);
    }
    .line-bottom.active{
        transform: translate(0, 0) scale(1, 1);
    }
</style>
