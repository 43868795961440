import axios from "axios";
const URL = process.env.VUE_APP_SERVER_URI+"login";

class Axios {
	//Get Posts
	static axios(){
		return axios;
	}
	static get(url,headers={}) {
		headers=this.getHeaders(headers);
		headers["authorization"]=this.getToken();      
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(url,{headers});				
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static post(url,data={},headers={}) {
		headers=this.getHeaders(headers);
        headers["authorization"]=this.getToken();
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(url,data,{headers});
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static put(url,data={},headers={}) {
		headers=this.getHeaders(headers);
        headers["authorization"]=this.getToken();
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.put(url,data,{headers});
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static delete(url,headers={}) {
		
        headers["authorization"]=this.getToken();
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.delete(url,{headers});
				resolve(res);
			} catch (error) {
				//console.log(error);
				reject(error);
			}
		})
    }
    static getToken(){
        var sesionKey =window.sessionStorage.getItem("sessionKey")
        if(sesionKey && JSON.parse(window.sessionStorage.getItem(sesionKey)))
            return JSON.parse(window.sessionStorage.getItem(sesionKey)).witchToken;
        else if(localStorage.getItem("witchToken"))
            return localStorage.getItem("witchToken");
        return null;
	}
	static getHeaders(headers){
		let filter=headers.filter?JSON.stringify(headers.filter):"{}";
		let include=headers.include?headers.include:false;				
		let includes=headers.includes?JSON.stringify(headers.includes):"null";
		let request={
			filter,
			include,
			includes			
		}
		if(!!headers.attributes)request["attributes"]=JSON.stringify(headers.attributes);
		if(!!headers.group)request["group"]=JSON.stringify(headers.group);
		if(!!headers.order)request["order"]=JSON.stringify(headers.order);
		if(!!headers.limit)request["limit"]=headers.limit;
		return request;
	}
}

export default Axios;