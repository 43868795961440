import axios from "./Axios.js";
const URL = process.env.VUE_APP_SERVER_URI+"customers";
class User {	
	static save(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL
			try {
				const res = await axios.axios().post(url+"/form",data);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
    static savePircure(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/save/picture"
			try {
                const formData = new FormData();
				formData.append("image",data.image);                
				const res = await axios.post(url,formData);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
    static login(data) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/auth"
			try {
                const res = await axios.post(url,data);
                if(data.rememberme){
					let {user,token}=res.data.token;
					localStorage.setItem("witchToken",token);
					localStorage.setItem("user",JSON.stringify(user));
				}
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
	}
	static find(id,headers={}) {
		return new Promise(async (resolve, reject) => {
			let url=URL+"/"+id
			try {
				const res = await axios.get(url,headers);
				resolve(res.data);
			} catch (error) {
				reject(error.response.data);
			}
		})
    }
	static async count(id,headers={}) {
		let url=URL+"/count"
		try {
			const res = await axios.get(url,headers);
			return res.data;
		} catch (error) {
			return (error.response.data);
		}
    }
	static async update ( email,userData ) {
		var url = (URL+"/"+email+"/me")
		try {
			var { data } = await axios.put(url,userData);
			return data
		} catch ( error ) {
			return error
		}
    }
    static async settingsUpdate ( args ) {
		var url = ( URL + 'me/devices/' + args.device+"/settings")
		try {
			var { data } = await axios.put(url,args.settings);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async resetPassRequest( args ) {
		var url = ( URL + 'resetpass')
		try {
			var { data } = await axios.post(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
	static async resetPass( args ) {
		var url = ( URL + 'me/credentials')
		try {
			var { data } = await axios.post(url,args);
			return data
		} catch ( error ) {
			return error
		}
	}
}

export default User;