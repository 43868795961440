<template>
  <v-parallax
    dark
    :height="appHeight"
    src="@/assets/images/985as20xc3as.jpg"
  >
      <div style="position:absolute;height:100%;width:100%;background:#1E1F2080;left:0" >
        
      </div>
    <v-container grid-list-xs>      
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12" sm="11" md="10" lg="8">
          <h5 class="subheading">
            Salón de belleza la brujita
          </h5>
          <h1 class="text-h4 font-weight-thin my-4">
            <WriteText :word-list="['TINTES','UÑAS DE ACRILICO','CORTES DE CABELLO','DEPILACION DE CEJA','ALASIADO']"/>
          </h1>
          <v-row justify="left">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="item in infoList" :key="item.subtitle">
              <InfoCard :text="item.text" :subtitle="item.subtitle" :list-options="item.listOptions"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>
<script>
import WriteText from './WriteText';
import InfoCard from './InfoCard';
import { mapState } from 'vuex';
export default {
    name:"Parallax",
    components:{
        WriteText,
        InfoCard
    },
    computed:{
        ...mapState(['appHeight']),
    },
    data:()=>({
      infoList:[
        {
          subtitle:'Dirección',
          text:'Cda. Sauces S/N local 4. Colonia tlalpexco. CP.  07188'
        },
        {
          subtitle:'Teléfonos',
          listOptions:[
            {
              icon:'phone',
              text:'5513341838'
            },
            {
              icon:'fab fa-whatsapp',
              text:'5514965093'
            }
          ]
        }
      ]
    }) 
}
</script>